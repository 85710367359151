export const extensivo = {
  name: "Extensivo",
  initDate: "2024-02-26 00:00:00",
  docs: {
    floripa: {
      presencial:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Extensivo+2024.pdf",
      },
      aoVivo:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Extensivo+Ao+Vivo+2024.pdf"
      }
    }
  },
  floripa: {
    presencial: {
      matutino: {
        1: {
          value: 11899.90,
          discount: 15,
        },
        12: {
          value: 1049.9,
          discount: 10,
        },
        16: {
          value: 874.90,
          discount: 0,
        },
      },
    },
    aoVivo: {
      matutino: {
        1: {
          value: 2079.9,
          discount: 20,
        },
        12: {
          value: 189.9,
          discount: 10,
        },
        16: {
          value: 159.9,
          discount: 0,
        },
      },
    },
  },
};

export const smart = {
  name: "Extensivo Smart",
  initDate: "2024-02-26 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        noturno: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Smart+Floripa+Noturno+2024.pdf",
        vespertino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Smart+Floripa+Vespertino+2024.pdf",
      },
      aoVivo:{
        vespertino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Smart+Floripa+Vespertino+Ao+vivo+2024.pdf"
      }
    },
    palhoca: {
      presencial: {
        noturno: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+Smart+Palho%C3%A7a+Noturno+2024.pdf",
      }

    },
  },
  floripa: {
    presencial: {
      vespertino: {
        1: {
          value: 7139.9,
          discount: 15,
        },
        12: {
          value: 629.9,
          discount: 10,
        },
        16: {
          value: 524.9,
          discount: 0,
        },
      },
      noturno: {
        1: {
          value: 7479.9,
          discount: 15,
        },
        12: {
          value: 659.9,
          discount: 10,
        },
        16: {
          value: 549.9,
          discount: 0,
        },
      },
    },
    aoVivo: {
      noturno: {
        1: {
          value: 1699.9,
          discount: 15,
        },
        12: {
          value: 149.9,
          discount: 10,
        },
        16: {
          value:124.9,
          discount: 0,
        },
      },
    },
  },
  palhoca: {
    presencial: {
      noturno: {
        1: {
          value: 5694.9,
          discount: 15,
        },
        12: {
          value: 499.9,
          discount: 10,
        },
        16: {
          value: 419.9,
          discount: 0,
        },
      },
    },
  },
};

export const intensivo = {
  name: "Intensivo UFSC",
  initDate: "2023-11-13 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+2023.pdf",
        vespertino: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Vespertino+2023.pdf",
        noturno: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Noturno+2023.pdf"
      },
      aoVivo:{
        matutino:"https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+Ao+vivo+2023.pdf",
        noturno:"https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Noturno+Ao+vivo+2023.pdf"
      }
    },
    palhoca:{
      presencial:{
        vespertino: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Palho%C3%A7a+Vespertino+2023.pdf",
      }
    }
  },
  floripa:{
    presencial:{
      matutino:{
        1:{
          value:719.90,
          discount: 10
        },
        2:{
          value: 399.90,
          discount: 0
        }
      },
      vespertino:{
        1:{
          value:719.90,
          discount: 10
        },
        2:{
          value: 399.90,
          discount: 0
        }
      },
      noturno:{
        1:{
          value:599.90,
          discount: 15
        },
        2:{
          value: 349.90,
          discount: 0
        }
      }
    },
    aoVivo:{
      matutino:{
        1:{
          value:270.0,
          discount: 10
        },
        2:{
          value: 150.0,
          discount: 0
        }
      },
      noturno:{
        1:{
          value:270.0,
          discount: 10
        },
        2:{
          value: 150.0,
          discount: 0
        }
      }
    }
  },
  palhoca: {
    presencial: {
      vespertino: {
        1: {
          value: 424.9,
          discount: 15,
        },
        2: {
          value: 249.90,
          discount: 0,
        }
      }
    },
  },
}

export const semiextensivo = {
  name: "Semiextensivo",
  initDate: "2024-07-29 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMIEXTENSIVO+MATUTINO+-+2024.2.pdf",
      },
      aoVivo:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMI+MATUTINO+AO+VIVO+2024.2.pdf",
      }

    },
  },
  floripa: {
    presencial: {
      matutino: {
        1: {
          value: 6119.9,
          discount: 10,
        },
        6: {
          value: 1074.9,
          discount: 5,
        },
        8: {
          value: 849.9,
          discount: 0,
        },
      },
    },
    aoVivo: {
      matutino: {
        1: {
          value: 1439.9,
          discount: 10,
        },
        6: {
          value: 249.9,
          discount: 5,
        },
        8: {
          value: 199.9,
          discount: 0,
        },
      },
    },
  },
};

export const semiextensivosmart = {
  name: "Semiextensivo Smart",
  initDate: "2024-07-29 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        vespertino: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMI+SMART+FLORIPA+VESPERTINO+2024.2.pdf",
        noturno: "https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMI+SMART+FLORIPA+NOTURNO+2024.2.pdf",
      },
      aoVivo:{
        noturno: "",
      }

    },
    palhoca:{
      presencial:{
        vespertino:"https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMI+SMART+PALHO%C3%87A+VESPERTINO+2024.2.pdf",
        noturno:"https://contratos-cursinhopro.s3.amazonaws.com/Contrato+SEMI+SMART+PALHO%C3%87A+NOTURNO+2024.2.pdf"
      }
    }
  },
  floripa: {
    presencial: {
      vespertino: {
        1: {
          value: 3739.9,
          discount: 15,
        },
        6: {
          value: 649.9,
          discount: 5,
        },
        8: {
          value: 549.9,
          discount: 0,
        },
      },
      noturno:{
        1: {
          value: 3399.32,
          discount: 15,
        },
        6: {
          value: 633.21,
          discount: 5,
        },
        8: {
          value: 499.9,
          discount: 0,
        },
      }
    },
    aoVivo: {
      noturno: {
        1: {
          value: 1349.9,
          discount: 15,
        },
        6: {
          value: 249.9,
          discount: 5,
        },
        8: {
          value: 199.9,
          discount: 0,
        },
      },
    },
  },
  palhoca:{
    presencial:{
      vespertino:{
        1: {
          value: 3739.9,
          discount: 15,
        },
        6: {
          value: 689.9,
          discount: 5,
        },
        8: {
          value: 549.9,
          discount: 0,
        },
      },
      noturno:{
        1: {
          value: 3399.9,
          discount: 15,
        },
        6: {
          value: 629.9,
          discount: 5,
        },
        8: {
          value: 499.9,
          discount: 0,
        },
      }
    }
  }
};

export const revisaenem = {
  name: "Revisa Enem",
  initDate: "2024-10-07 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+2023.pdf",
      },
      aoVivo:{
        matutino:"https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+Ao+vivo+2023.pdf",
      }
    },
  },
  floripa:{
    presencial:{
      matutino:{
        1:{
          value:799.90,
          discount: 20
        },
        2:{
          value: 499.90,
          discount: 0
        }
      },
    },
    aoVivo:{
      matutino:{
        1:{
          value:399.90,
          discount: 15
        },
        2:{
          value: 199.90,
          discount: 0
        }
      }
    }
  },
}

export const revisaufsc = {
  name: "Revisa Enem",
  initDate: "2024-10-07 00:00:00",
  checkoutAoVivo: "https://pag.ae/7ZoUzNww3",
  docs: {
    floripa: {
      presencial:{
        matutino: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+2023.pdf",
      },
      aoVivo:{
        matutino:"https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+Ao+vivo+2023.pdf",
      }
    },
    palhoca: {
      presencial: {
        noturno: "https://contratos-cursinhopro.s3.amazonaws.com/INTENSIVO+Matutino+2023.pdf"
      }
    }
  },
  floripa:{
    presencial:{
      matutino:{
        1:{
          value:799.90,
          discount: 20
        },
        2:{
          value: 499.90,
          discount: 0
        }
      },
      vespertino:{
        1:{
          value:799.90,
          discount: 20
        },
        2:{
          value: 499.90,
          discount: 0
        }
      },
      noturno:{
        1:{
          value:679.90,
          discount: 15
        },
        2:{
          value: 399.90,
          discount: 0
        }
      },
    },
    aoVivo:{
      matutino:{
        1:{
          value:399.90,
          discount: 15
        },
        2:{
          value: 199.90,
          discount: 0
        }
      },
      noturno:{
        1:{
          value:269.90,
          discount: 10
        },
        2:{
          value: 149.90,
          discount: 0
        }
      }
    }
  },
  palhoca: {
    presencial: {
      noturno: {
        1:{
          value: 459.90,
          discount: 15
        },
        2:{
          value: 274.9,
          discount: 0
        }
      }
    }
  }
}



